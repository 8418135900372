<script>
  import { onMount } from "svelte";
  import axios from "axios";

  let selectedFile = null;
  let totalSize = 0; // Variable para almacenar el tamaño total de los archivos seleccionados en bytes
  const token = "eyJhbGciOiJIUzUxMiJ9.eyJyb2xlcyI6WyJST0xFX1VTRVIiXSwibmFtZSI6IkpvcmdlIENhbGRlcm9uIiwiaWQiOjcsImVtYWlsT3JQaG9uZSI6ImNhbGRlcm9uLmpvcmdlNEBnbWFpbC5jb20iLCJzdWIiOiI3IiwiaWF0IjoxNzEyNzEwNTExLCJleHAiOjE3NDQyNDY1MTF9.bvUq4kgZMK6Slv8NtS8ER4lL-qLsPsBcWpagpvyNopcee7vLK46k_eQrTngE2aAMw6HAWm2K372ftQZBuuqotg";
  let responseText = "";

  const handleFileChange = (event) => {
    selectedFile = event.target.files[0];
    totalSize = selectedFile ? selectedFile.size : 0; // Actualizar el tamaño total de los archivos seleccionados
  };

  const removeFile = () => {
    selectedFile = null;
    totalSize = 0;
  };

  const uploadFile = async () => {
    console.log("Tipo de archivo seleccionado:", selectedFile.type);

    try {
      if (
        !selectedFile ||
        (selectedFile.type !== "application/zip" &&
          selectedFile.type !== "application/x-zip-compressed")
      ) {
        console.warn("No se ha seleccionado un archivo ZIP.");
        return;
      }

      const formData = new FormData();
      formData.append("file", selectedFile); // Agregar el archivo al FormData
      responseText = "Subiendo archivos";
      setTimeout(() => {
        responseText = ""
      }, 5000);
      const response = await axios.post(
        "https://sandbox.honey-dates.com/api/v1/ingredients/uploadzip",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      responseText = "Archivos Subidos Exitosamente";

      // Limpiar la selección después de la subida exitosa
      removeFile();

      // Aquí puedes agregar cualquier lógica adicional después de la subida exitosa del archivo
    } catch (error) {
      responseText = "Error al Subir archivos";
      setTimeout(() => {
        responseText = ""
      }, 5000);
      // Aquí puedes manejar el error de subida del archivo, por ejemplo, mostrando un mensaje al usuario
    }
  };

  onMount(() => {
    // Aquí podrías realizar cualquier inicialización necesaria al cargar el componente
  });
</script>

<div class="flex justify-around my-5">
  <input
    type="file"
    accept=".zip"
    on:change={handleFileChange}
    class="bg-brown-500 p-3 rounded-lg text-white font-bold"
  />
  {#if selectedFile}
    <button
      class="bg-brown-500 p-2 text-white rounded-lg font-bold"
      on:click={uploadFile}>Subir archivo</button
    >
  {/if}
</div>

<div class="my-6">
  {#if selectedFile}
    <ul>
      <li class="text-orange-500 font-bold text-xl">
        {selectedFile.name} - {Math.round((totalSize / (1024 * 1024)) * 100) /
          100} MB <!-- Mostrar el tamaño del archivo en MB -->
        <button on:click={removeFile}>Eliminar</button>
      </li>
    </ul>
  {:else}
    <p class="text-center text-gray-500">Sin archivo seleccionado</p>
  {/if}
</div>
<div class="w-ful text-center p-4 font-bold">{responseText}</div>
