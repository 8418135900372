<script>
  import { onMount } from 'svelte';
  import axios from 'axios';

  export let NumCatalog = 0;

  

  let formData = [{
    catalogType: {
      id: NumCatalog,
      code: "",
      description: ""
    },
    description: '',
    idCatalogType: NumCatalog,
    name: '',
    translateSave : [
      {
        column : "name",
        language : "en",
        value : ""
      },
      {
        column : "description",
        language : "en",
        value : ""
      }
    ],
    slug: '',
    imgUrl: '',
    videoUrl: ''
  }];

  let successMessage = '';
  let errorMessage = '';

  const token = 'eyJhbGciOiJIUzUxMiJ9.eyJyb2xlcyI6WyJST0xFX1VTRVIiXSwibmFtZSI6IkpvcmdlIENhbGRlcm9uIiwiaWQiOjcsImVtYWlsT3JQaG9uZSI6ImNhbGRlcm9uLmpvcmdlNEBnbWFpbC5jb20iLCJzdWIiOiI3IiwiaWF0IjoxNzEyNzEwNTExLCJleHAiOjE3NDQyNDY1MTF9.bvUq4kgZMK6Slv8NtS8ER4lL-qLsPsBcWpagpvyNopcee7vLK46k_eQrTngE2aAMw6HAWm2K372ftQZBuuqotg';

  const postUrl = `https://sandbox.honey-dates.com/api/v1/catalog`;

  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  };

  async function sendPostRequest() {
  console.log(formData);
  if (formData[0].imgUrl && formData[0].imgUrl.length > 4) {
    formData[0].imgUrl = "/media/ingredients/img/allergy/" + formData[0].imgUrl;
  }
  try {
    const response = await axios.post(postUrl, JSON.stringify(formData), { headers });
    console.log('Respuesta del servidor:', response.data);
    successMessage = 'Se agrego correctamente';
    clearForm();
    setTimeout(() => {
      successMessage = '';
      location.reload();
    }, 3000);
  } catch (error) {
    console.error('Error al enviar la solicitud POST:', error);
    errorMessage = 'Hubo un error. Por favor, inténtalo de nuevo.';
    setTimeout(() => {
      errorMessage = '';
    }, 5000);
  }
}



  function clearForm() {
    formData = [{
      catalogType: {
        id: NumCatalog
      },
      description: '',
      idCatalogType: NumCatalog,
      name: '',
      translateSave : [
      {
        column : "name",
        language : "en",
        value : ""
      },
      {
        column : "description",
        language : "en",
        value : ""
      }
    ],
      slug: '',
      imgUrl: '',
      videoUrl: ''
    }];
  }

  function handleSubmit(event) {
    event.preventDefault();
    sendPostRequest();
  }
</script>

<form on:submit={handleSubmit} class="max-w-md mx-auto p-6 bg-gray-100 rounded-lg shadow-md">
  <div class="mb-4">
    <label class="block text-gray-700 font-bold mb-2" for="name">Nombre:</label>
    <input type="text" id="name" class="w-full p-2 border rounded-md" required bind:value={formData[0].name} />
  </div>
  <div class="mb-4">
    <label class="block text-gray-700 font-bold mb-2" for="name">Nombre en Ingles:</label>
    <input type="text" id="name" class="w-full p-2 border rounded-md"  bind:value={formData[0].translateSave[0].value} />
  </div>
  <div class="mb-4">
    <label class="block text-gray-700 font-bold mb-2" for="description">Descripción:</label>
    <input type="text" id="description" class="w-full p-2 border rounded-md" bind:value={formData[0].description} />
  </div>
  <div class="mb-4">
    <label class="block text-gray-700 font-bold mb-2" for="description">Descripción en Ingles:</label>
    <input type="text" id="description" class="w-full p-2 border rounded-md" bind:value={formData[0].translateSave[1].value} />
  </div>

  <div class="mb-4">
    <label class="block text-gray-700 font-bold mb-2" for="slug">Slug:</label>
    <input type="text" id="slug" maxlength="10" class="w-full p-2 border rounded-md" required bind:value={formData[0].slug} />
  </div>

  <div class="mb-4">
    <label class="block text-gray-700 font-bold mb-2" for="imgUrl">URL de Imagen:</label>
    <input type="text" id="imgUrl" class="w-full p-2 border rounded-md" bind:value={formData[0].imgUrl} />
  </div>

  <div class="mb-4">
    <label class="block text-gray-700 font-bold mb-2" for="videoUrl">URL de Video:</label>
    <input type="text" id="videoUrl" class="w-full p-2 border rounded-md" bind:value={formData[0].videoUrl} />
  </div>

  <button type="submit" class="w-full p-3 bg-brown-500 text-white font-bold rounded-md shadow-lg">Enviar</button>

  {#if successMessage}
    <div class="text-green-600 w-full text-center font-bold ">{successMessage}</div>
  {/if}
  {#if errorMessage}
    <div class="text-red-600 w-full text-center font-bold ">{errorMessage}</div>
  {/if}
</form>
