<script>
  import { onMount } from "svelte";
  import axios from "axios";
  import Modal from "components/Modal.svelte";

  export let idCatalog = 0;
  const token =
    "eyJhbGciOiJIUzUxMiJ9.eyJyb2xlcyI6WyJST0xFX1VTRVIiXSwibmFtZSI6IkpvcmdlIENhbGRlcm9uIiwiaWQiOjcsImVtYWlsT3JQaG9uZSI6ImNhbGRlcm9uLmpvcmdlNEBnbWFpbC5jb20iLCJzdWIiOiI3IiwiaWF0IjoxNzEyNzEwNTExLCJleHAiOjE3NDQyNDY1MTF9.bvUq4kgZMK6Slv8NtS8ER4lL-qLsPsBcWpagpvyNopcee7vLK46k_eQrTngE2aAMw6HAWm2K372ftQZBuuqotg";
  let idiomaNum = true;
  let ModalContent = 0;
  let selectedItem = null;
  let formData = {
    name: "",
    translateSave: [
      {
        column: "name",
        language: "en",
        value: "",
      },
      {
        column: "description",
        language: "en",
        value: "",
      },
    ],
    description: "",
    id: null,
    imgUrl: "",
    slug: "",
    videoUrl: "",
    idCatalogType: idCatalog,
  };

  let catalogItems = [];
  let error = null;

  // Función para cerrar el modal
  function closeModal() {
    selectedItem = null;
  }

  // Función para establecer el valor de idCatalogType en formData
  function setCatalogType() {
    formData.idCatalogType = selectedItem.idCatalogType;
  }

  // Cargar todos os catálogos
  const obtenerCatalogItems = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Accept-Language": "es",
      };
      const response = await axios.get(
        `https://sandbox.honey-dates.com/api/v1/catalog/type/${idCatalog}`,
        { headers }
      );
      console.log(response);
      catalogItems = response.data.data;
      catalogItems.forEach((item) => {
        if (item.translate) {
          item.translate = JSON.parse(item.translate);
        }
      });

      console.log(catalogItems);
    } catch (error) {
      console.error("Error al obtener los ítems del catálogo:", error);
    }
  };

  // Editar todos los catálogos

  const editar = async (event) => {
    event.preventDefault();
    setCatalogType();
    formData.imgUrl = "/media/ingredients/img/allergy/" + formData.imgUrl;

    console.log(formData);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Accept-Language": "es",
      };

      const url = `https://sandbox.honey-dates.com/api/v1/catalog/${selectedItem.id}`;
      const response = await axios.put(url, formData, { headers });
      console.log(response);

      // Actualizar catálogo después de la edición
      obtenerCatalogItems();
      closeModal();
    } catch (err) {
      console.error(err);
    }
  };

  // Eliminar Catálogos
  const eliminar = async (event) => {
    event.preventDefault();
    setCatalogType();
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const url = `https://sandbox.honey-dates.com/api/v1/catalog/${selectedItem.id}`;
      const response = await axios.delete(url, { headers });
      console.log(response);

      // Actualizar catálogo después de la eliminación
      obtenerCatalogItems();
      closeModal();
    } catch (err) {
      console.error(err);
    }
  };

  // Procesamiento adicional para el valor del input imgUrl
  $: {
    if (formData.imgUrl) {
      const index = formData.imgUrl.lastIndexOf("allergy/");
      if (index !== -1) {
        formData.imgUrl = formData.imgUrl.substring(index + 8);
      }
    }
  }

  onMount(() => {
    obtenerCatalogItems();
  });
</script>

{#if catalogItems.length > 0}
  <div>
    {#each catalogItems as item}
      <div
        class="flex justify-between items-center px-12 py-6 hover:bg-black border shadow-2xl rounded-lg my-4"
      >
        <div>
          <h2>Nombre: {item.name}</h2>
          <h2>
            Nombre en Ingles: {item.translate
              ? item.translate[0].value
              : "Sin Traducción"}
          </h2>
          <h3>ID: {item.id}</h3>
          <h3>Slug {item.slug}</h3>
          <p>
            descripción: {item.description && item.description !== "NULL"
              ? item.description
              : "Sin descripción"}
          </p>
          {#if item.translate && item.translate.length > 1}
            <p>Descripción en Ingles: {item.translate[0].value}</p>
          {/if}
        </div>
        {#if item.imgUrl}
          <img src={item.imgUrl} style="width: auto; height: 100px;" alt="" />
        {:else if item.videoUrl}
          <!-- svelte-ignore a11y-media-has-caption -->
          <video
            src={item.videoUrl}
            style="width: auto; height: 300px;"
            controls
          ></video>
        {/if}

        <div class="flex flex-col">
          <button
            class=" m-3 bg-lightBlue-500 text-white font-bold py-2 px-4 rounded-lg"
            style="height: fit-content;"
            type="button"
            on:click={() => {
              selectedItem = item;
              // Llenar formData con los valores de selectedItem
              formData = {
                name: item.name,
                translateSave: [
                  {
                    column: "name",
                    language: "en",
                    value: item.translate ? item.translate[0].value : "",
                  },
                  {
                    column: "description",
                    language: "en",
                    value:
                      item.translate ? item.translate[1].value : "",
                  },
                ],
                description:
                  item.description !== "NULL" ? item.description : "",
                id: item.id,
                imgUrl: item.imgUrl || "",
                slug: item.slug,
                videoUrl:
                  item.videoUrl === "https://sandbox.honey-dates.com" || ""
                    ? ""
                    : item.videoUrl,
              };
              ModalContent = 0; // Establecer el contenido del modal como editar
            }}>Editar</button
          >
          <button
            class=" m-3 bg-red-500 text-white font-bold py-2 px-4 rounded-lg"
            style="height: fit-content;"
            type="button"
            on:click={() => {
              selectedItem = item;
              ModalContent = 1;
            }}>Eliminar</button
          >
        </div>
      </div>
    {/each}
  </div>
{:else if error}
  <p>Error: {error}</p>
{:else}
  <p>Loading...</p>
{/if}

{#if selectedItem}
  <Modal on:close={closeModal}>
    {#if ModalContent === 0}
      <!-- Editar -->
      <form
        on:submit={editar}
        style="min-width: 400px;"
        class="max-w-md mx-auto p-6 bg-gray-100 rounded-lg shadow-md"
      >
        <div class="mb-4">
          <label class="block text-gray-700 font-bold mb-2" for="name"
            >Nombre:</label
          >
          <input
            type="text"
            id="name"
            class="w-full p-2 border rounded-md"
            required
            bind:value={formData.name}
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 font-bold mb-2" for="translateSave"
            >Nombre en Ingles:</label
          >
          <input
            type="text"
            id="translateSave"
            class="w-full p-2 border rounded-md"
            bind:value={formData.translateSave[0].value}
          />
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 font-bold mb-2" for="description"
            >Descripción:</label
          >
          <input
            type="text"
            id="description"
            class="w-full p-2 border rounded-md"
            bind:value={formData.description}
          />
        </div>
        <div class="mb-4">
          <label class="block text-gray-700 font-bold mb-2" for="description"
            >Descripción en Ingles:</label
          >
          <input
            type="text"
            id="descriptionEng"
            class="w-full p-2 border rounded-md"
            bind:value={formData.translateSave[0].value}
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 font-bold mb-2" for="id">ID:</label>
          <input
            type="number"
            id="id"
            class="w-full p-2 border rounded-md"
            bind:value={formData.id}
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 font-bold mb-2" for="imgUrl"
            >URL de Imagen:</label
          >
          <input
            type="text"
            id="imgUrl"
            class="w-full p-2 border rounded-md"
            bind:value={formData.imgUrl}
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 font-bold mb-2" for="slug"
            >Slug:</label
          >
          <input
            type="text"
            maxlength="10"
            id="slug"
            class="w-full p-2 border rounded-md"
            required
            bind:value={formData.slug}
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 font-bold mb-2" for="videoUrl"
            >URL de Video:</label
          >
          <input
            type="text"
            id="videoUrl"
            class="w-full p-2 border rounded-md"
            bind:value={formData.videoUrl}
          />
        </div>

        <button
          type="submit"
          class="w-full p-3 bg-brown-500 text-white font-bold rounded-md shadow-lg"
          >Guardar Cambios</button
        >
      </form>
    {:else if ModalContent === 1}
      <!-- Eliminar -->
      <div>
        <h2 class="text-xl mb-4">
          ¿Estás seguro de que deseas eliminar {selectedItem.name}?
        </h2>
        <form on:submit={eliminar}>
          <div class="my-4 flex justify-around items-center">
            <button
              class="bg-blueGray-600 text-white font-bold py-2 px-4 rounded-lg"
              style="height: fit-content;"
              type="button"
              on:click={closeModal}>Cancelar</button
            >
            <button
              class="bg-red-500 text-white font-bold py-2 px-4 rounded-lg"
              style="height: fit-content;"
              type="submit">Eliminar</button
            >
          </div>
        </form>
      </div>
    {/if}
  </Modal>
{/if}
